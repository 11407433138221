import './src/styles/global.css';
import './src/styles/loader.css';

import React from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
export const wrapRootElement = ({ element }) =>{
    return (
        <GoogleReCaptchaProvider reCaptchaKey="6LcdW8seAAAAALq4irnRZR4L08OxM2xHwCCD511e">
            {element}
        </GoogleReCaptchaProvider>
    )
}